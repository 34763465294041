import React, { useEffect, useState, useCallback } from 'react';
import { Input, Tag, Tooltip } from 'antd4';
import op from 'object-path';

import './Fields.scss';

const Field = ({
  path,
  width,
  label,
  editable,
  disabled,
  isValid = value => true,
  formatLabel,
  data,
  updateField
}) => {
  let dataValue = op.get(data, path);
  const [value, setValue] = useState(dataValue);
  const [isDirty, setDirty] = useState(false);
  const [statusClass, setStatusClass] = useState('');
  const [isFormatError, setFormatError] = useState(false);

  useEffect(() => {
    // auto update state if data or path changes
    const updateValue = op.get(data, path);
    setValue(updateValue);
  }, [data, path]);

  useEffect(() => {
    // auto update status class if format error
    if (isFormatError) {
      setStatusClass('save-error');
    } else {
      setStatusClass('');
    }
  }, [isFormatError]);

  const onSave = useCallback(
    async event => {
      console.log('onSave');
      if (!isValid(event.target.value)) {
        setFormatError(true);
        console.log('invalid format: ', event.target.value);
        return;
      }
      const value = event?.target?.value;
      if (isDirty) {
        let response;
        try {
          response = await updateField(path, value);
        } catch (error) {
          response = { ok: false };
        }

        if (response?.ok) {
          // console.log('response ok, setting dirty to FALSE. adding save-success' );
          setDirty(false);
          setStatusClass('save-success');

          setTimeout(() => {
            setStatusClass('');
          }, 3000);
        } else {
          setStatusClass('save-error');
        }
      } else {
        // console.log('not dirty');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDirty, updateField, path]
  );

  if (!op.has(data, path)) {
    return null;
  }

  return (
    <div key={path} className={`relative input-container w-${width ?? '25'}`}>
      <Tooltip
        open={isFormatError}
        placement="topLeft"
        title={
          formatLabel ? (
            <span>Formatos suportados: {formatLabel}</span>
          ) : (
            'Formato inválido'
          )
        }
      ></Tooltip>
      <Input
        className={'input ' + statusClass}
        value={value}
        disabled={disabled}
        onBlur={event => {
          if (editable === false || isFormatError) {
            event.preventDefault();
            return null;
          }
          onSave(event);
        }}
        onPressEnter={event => {
          if (editable === false || isFormatError) {
            event.preventDefault();
            return null;
          }
          onSave(event);
        }}
        onChange={event => {
          if (editable === false) {
            event.preventDefault();
            return null;
          }

          setDirty(true);
          setValue(event.target.value);
          if (isValid(event.target.value)) {
            setFormatError(false);
          } else {
            setFormatError(true);
          }
        }}
        prefix={<Tag className="input-prefix border-radius">{label}</Tag>}
      />
    </div>
  );
};

export default Field;
