import React from 'react';
import autobind from 'auto-bind/react';
import { withRouter, Link } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  DatePicker,
  InputNumber,
  Tooltip,
  Modal
} from 'antd4';
import {
  UpOutlined,
  PlusOutlined,
  ClearOutlined,
  SyncOutlined,
  ExpandAltOutlined,
  FileExcelOutlined,
  CloudUploadOutlined,
  MessageTwoTone
} from '@ant-design/icons';

import { exportClients } from './../../../components/export/exportExcel';
import exportRepresentationsExcel from './../../../components/export/exportRepresentationsExcel';
import './TableHeader.scss';
import {
  processExcelFile,
  exportPaymentImportReport
} from '../../../excel/paymentsExcel';
import { postBulkServicePayments } from '../network/excelPayments';
import moment from 'moment';

const { Search } = Input;

class TableHeader extends React.Component {
  formRef = React.createRef();
  excelUploadRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      search: this.props.searching,
      excelUploading: false,
      excelError: null,
      excelResult: null
    };

    autobind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.showCreateForm !== this.props.showCreateForm) {
      this.formRef?.current?.resetFields?.(); // eslint-disable-line no-unused-expressions
    }
  }

  async onFinish(values) {
    const response = await this.props.onCreate?.(values);
    if (response.ok) {
      await this.formRef.current?.resetFields?.();
      const { location, history } = this.props;
      let result;
      const url = location?.pathname.replace(/\/$/, '');
      result = url + '/client/' + response.data?._id;

      return history.push(result);
    } else {
      // nothing
    }
  }

  clickExcelUploadInput() {
    if (this.excelUploadRef?.current) {
      this.excelUploadRef.current.click();
    }
  }

  async onExcelFileChange(e) {
    const excel = e.target?.files?.[0];

    this.setState({ showExcelModal: true });

    if (excel) {
      var reader = new FileReader();
      const that = this;
      reader.onload = async function(event) {
        let sheetJson;
        try {
          sheetJson = await processExcelFile(event.target.result);
          sheetJson = sheetJson.slice(2); // remove first two rows
          console.log({ sheetJson });
          
          const response = await postBulkServicePayments(sheetJson);

          if (!response?.ok || !response?.data) {
            throw new Error(
              'Erro de ligação a carregar pagamentos, por favor tente novamente.'
            );
          }

          that.setState({
            excelUploading: false,
            excelResult: response.data,
            excelError: null
          });
        } catch (error) {
          that.setState({
            excelResult: null,
            excelUploading: false,
            excelError: error.message
          });
        }
      };
      this.setState({ excelUploading: true });
      reader.readAsArrayBuffer(excel);
    } else {
      this.setState({
        excelUploading: false,
        excelError:
          'Erro: ficheiro corrupto, incompatível, ou não encontrado. Gere um novo ficheiro e tente novamente.'
      });
      // ERRO
    }
  }

  render() {
    return (
      <div className="table-header">
        <div className="action-container">
          <div className="actions-start">
            <Search
              value={this.state.search ?? ''}
              placeholder="Procurar"
              size="small"
              onChange={e => {
                console.log(e.target.value);
                this.setState({ search: e.target?.value });
              }}
              onSearch={() => {
                return this.props?.search?.(this.state.search);
              }}
              className="search-input pr-3"
            />
            <Tooltip title={'Limpar pesquisa'}>
              <Button
                className="clear-search mr-3"
                size="small"
                type={this.props.searching ? 'primary' : 'default'}
                icon={<ClearOutlined></ClearOutlined>}
                shape="circle"
                onClick={() => {
                  console.log('clear searchiiiii -1');
                  this.setState({ search: '' });

                  console.log(
                    'clear searchiiiii -2',
                    this.state.search,
                    this.props?.search?.('')
                  );
                  return this.props?.search?.('');
                }}
              ></Button>
            </Tooltip>
          </div>
          <div className="actions-center d-flex justify-content-center align-items-center">
            <Tooltip title={'Criar Cliente'}>
              <Button
                className="add-client"
                size="small"
                type="primary"
                ghost
                icon={
                  this.props.showCreateForm ? (
                    <UpOutlined></UpOutlined>
                  ) : (
                    <PlusOutlined></PlusOutlined>
                  )
                }
                shape="circle"
                onClick={() => this.props?.toggleCreateForm?.()}
              ></Button>
            </Tooltip>
          </div>
          <div className="actions-end">
            <Tooltip title={'Importar Excel de Pagamentos'}>
              <Button
                className="refresh-list mr-2 flex items-center"
                size="small"
                type="primary"
                ghost
                icon={
                  <CloudUploadOutlined className="mr-2"></CloudUploadOutlined>
                }
                shape="round"
                onClick={async () => {
                  // console.log(
                  //   'Clientesss:',
                  //   this.props.sortedList,
                  //   this.props.expandAll
                  // );
                  if (!this.state.excelUploading) {
                    this.clickExcelUploadInput();
                  }
                }}
              >
                <input
                  className="hidden"
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  multiple={false}
                  onChange={this.onExcelFileChange}
                  ref={this.excelUploadRef}
                ></input>
                {this.state.excelUploading ? (
                  ''
                ) : (
                  <span className="text-xs">Pagamentos</span>
                )}
              </Button>
            </Tooltip>
            <Tooltip title={'Exportar Representações Fiscais para excel'}>
              <Button
                className="refresh-list mr-2 flex items-center text-xs"
                size="small"
                type="primary"
                ghost
                icon={<FileExcelOutlined></FileExcelOutlined>}
                shape="round"
                onClick={async () => {
                  // console.log(
                  //   'Clientesss:',
                  //   this.props.sortedList,
                  //   this.props.expandAll
                  // );
                  await exportRepresentationsExcel();
                }}
              >
                <span className="text-xs">Representações</span>
              </Button>
            </Tooltip>
            <Tooltip title={'Exportar lista de clientes ID para excel'}>
              <Button
                className="refresh-list mr-2 flex items-center"
                size="small"
                type="primary"
                ghost
                icon={<FileExcelOutlined></FileExcelOutlined>}
                shape="round"
                onClick={async () => {
                  // console.log(
                  //   'Clientesss:',
                  //   this.props.sortedList,
                  //   this.props.expandAll
                  // );
                  await exportClients(
                    this.props.sortedList,
                    this.props.expandAll
                  );
                }}
              >
                <span className="text-xs">Clientes</span>
              </Button>
            </Tooltip>
            {/* <Tooltip title={'Filtros e Relatórios de Clientes'}>
              <Button
                className="refresh-list mr-2"
                size="small"
                type="primary"
                ghost
                icon={<ExportOutlined></ExportOutlined>}
                shape="circle"
                onClick={() => {
                  this.props.setShowDrawer(true);
                }}
              ></Button>
            </Tooltip> */}
            <Tooltip placement="topLeft" title={'Enviar Mensagens'}>
              <Link to={'/id/messaging'}>
                <Button
                  // style={{ width: 100 }}
                  className="refresh-list mr-2"
                  size="small"
                  type="primary"
                  ghost
                  icon={
                    <MessageTwoTone twoToneColor="#52c41a"> </MessageTwoTone>
                  }
                  shape="circle"
                  onClick={() => this.props?.refresh?.()}
                ></Button>
              </Link>
            </Tooltip>
            <Tooltip
              placement="topLeft"
              title={'Expandir familiares na lista de clientes'}
            >
              <Button
                className="expand-all  mr-2"
                size="small"
                ghost
                type="primary"
                icon={<ExpandAltOutlined></ExpandAltOutlined>}
                shape="circle"
                onClick={() => this.props?.toggleExpandAll?.()}
              ></Button>
            </Tooltip>
            <Tooltip placement="topLeft" title={'Obter dados mais recentes'}>
              <Button
                className="refresh-list mr-2"
                size="small"
                type="primary"
                icon={<SyncOutlined></SyncOutlined>}
                shape="circle"
                onClick={() => this.props?.refresh?.()}
              ></Button>
            </Tooltip>
          </div>
        </div>
        <div
          className={`form-container${
            this.props.showCreateForm ? ' visible' : ''
          } d-flex flex-col justify-content-center align-items-start`}
        >
          <div className="form-inner">
            <h4>Novo Cliente</h4>
            <Form
              className="form-flex"
              ref={this.formRef}
              onFinish={this.onFinish}
            >
              <Form.Item
                label="Requerente"
                className="form-item"
                name="applicant"
                rules={[{ required: false }]}
              >
                <Input className="form-input" />
              </Form.Item>
              <Form.Item
                label="Agência"
                className="form-item"
                name="agency"
                rules={[{ required: false }]}
              >
                <Input className="form-input" />
              </Form.Item>
              <Form.Item
                className="form-item"
                name="nome"
                label="Nome"
                rules={[{ required: false }]}
              >
                <Input className="form-input" />
              </Form.Item>
              <Form.Item
                className="form-item"
                name="nif"
                label="NIF"
                rules={[{ required: true }]}
              >
                <Input className="form-input" />
              </Form.Item>
              <Form.Item
                className="form-item"
                name="nif_data_pedido"
                label="Data Pedido NIF"
                rules={[{ required: false }]}
              >
                <DatePicker className="form-input" />
              </Form.Item>
              <Form.Item
                className="form-item"
                name="serviceType"
                label="Tipo Serviço"
                rules={[{ required: false }]}
              >
                <Input className="form-input" />
              </Form.Item>
              <Form.Item
                className="form-item"
                name="serviceValue"
                label="Valor Serviço"
                rules={[{ required: false }]}
              >
                <InputNumber className="form-input" />
              </Form.Item>
              <Form.Item
                className="form-item"
                name="serviceContractDate"
                label="Data Contrato Serviço"
                rules={[{ required: false }]}
              >
                <DatePicker className="form-input" />
              </Form.Item>
              <Form.Item
                label="Dossier"
                className="form-item"
                name="dossier"
                rules={[{ required: false }]}
              >
                <Input className="form-input" />
              </Form.Item>
              <Form.Item
                className="form-item"
                name="juris"
                label="Juris"
                rules={[{ required: false }]}
              >
                <Input className="form-input" />
              </Form.Item>
              <Form.Item
                label="Morada PT"
                className="form-item"
                name="portugal_fiscal_residence"
                rules={[{ required: false }]}
              >
                <Input className="form-input" />
              </Form.Item>
              <Form.Item
                className="form-item"
                name="nacionalidade"
                label="Nacionalidade"
                rules={[{ required: false }]}
              >
                <Input className="form-input" />
              </Form.Item>
              <Form.Item className="form-item"></Form.Item>
              <Form.Item className="form-item"></Form.Item>
              <Form.Item className="form-item">
                <div className=" w-100 d-flex flex-row align-items-center justify-content-end">
                  <Button type="primary" htmlType="submit">
                    Submeter
                  </Button>
                </div>
              </Form.Item>
              <div className="form-flex"></div>
            </Form>
          </div>
        </div>
        {!!this.state.showExcelModal && (
          <Modal
            width="90vw"
            bodyStyle={{
              borderTop: '1px solid #e2e2e2',
              borderBottom: '1px solid #e2e2e2',
              marginTop: 12,
              paddingTop: 12,
              paddingBottom: 12 * 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              textAlign: 'left',
              fontSize: 12 * 1.2
            }}
            open={true}
            closeable={this.state.excelError || !this.state.excelUploading}
            onCancel={() => {
              this.setState({
                excelError: null,
                excelUploading: false,
                showExcelModal: false,
                excelResult: null
              });
              if (this.excelUploadRef?.current?.value) {
                this.excelUploadRef.current.value = '';
              }
              this.props?.refresh?.();
            }}
            footer={null}
          >
            <div className="w-full flex items-center justify-start mb-8">
              <button
                style={{ borderStyle: 'solid', borderWidth: '1px !important' }}
                className="w-1/5 px-2 py-1 text-sm text-sky-500 mr-2 rounded-xl border border-sky-600 bg-white hover:bg-sky-600 hover:text-white"
                onClick={() => {
                  exportPaymentImportReport(this.state.excelResult);
                }}
              >
                Exportar para Excel
              </button>
              <div className="text-2xl text-center w-3/5">
                Importação - Pagamentos de Serviço
              </div>
              <div className="w-1/5"></div>
            </div>
            {this.state.excelError ? (
              <div>Erro: {this.state.excelError}</div>
            ) : this.state.excelUploading ? (
              <div>A carregar pagamentos de serviço</div>
            ) : (
              <div className=" w-full h-auto flex flex-col items-start justify-start border-t min-h-16 bg-sky-100 text-sm">
                <div
                  className={`w-full flex justify-start items-stretch border-r border-b `}
                >
                  <div className="w-1/12 flex justify-start items-center break-all px-2 border-l">
                    Resultado
                  </div>
                  <div className="w-1/12 flex justify-start items-center break-all px-2 border-l">
                    Erro
                  </div>
                  <div className="w-1/12 flex justify-start items-center px-2 border-l">
                    Nome
                  </div>
                  <div className="w-1/12 flex justify-start items-center px-2 border-l">
                    NIF
                  </div>
                  <div className="w-1/12 flex justify-start items-center px-2 border-l">
                    Valor Recebido
                  </div>
                  <div className="w-1/12 flex justify-start items-center px-2 border-l">
                    Data Rec.
                  </div>
                  <div className="w-1/12 flex justify-start items-center px-2 border-l">
                    Ano Serviço
                  </div>
                  <div className="w-2/12 flex justify-start items-center break-all px-2 border-l">
                    Substituição?
                    <br />
                    (Nº Fatura)
                  </div>
                  <div className="w-3/12 flex justify-start items-center break-all px-2 border-l">
                    Observações
                  </div>
                </div>
                {this.state.excelResult?.map((item, index) => {
                  // const example = {
                  //   received_date: Date,
                  //   service_year: Date,
                  //   value_received: Number,
                  //   observation: String,
                  //   clientName: String,
                  //   clientNif: String,
                  //   isUpdate: Boolean
                  // };
                  const isEven = index % 2 === 0;
                  const isError = item.result !== 'SUCCESS';
                  return (
                    <div
                      key={index}
                      className={`w-full flex justify-start items-stretch border-r border-b ${
                        isError
                          ? 'bg-red-50'
                          : isEven
                          ? 'bg-white'
                          : 'bg-gray-50'
                      }`}
                    >
                      <div className="w-1/12 flex justify-start items-center break-all px-2 border-l">
                        <div className="table">{item.result}</div>
                      </div>
                      <div className="w-1/12 flex justify-start items-center break-normal px-2 border-l">
                        <div className="table">{item.message}</div>
                      </div>
                      <div className="w-1/12 flex justify-start items-center break-normal px-2 border-l">
                        <div className="table">{item.clientName}</div>
                      </div>
                      <div className="w-1/12 flex justify-start items-center break-all px-2 border-l">
                        <div className="table">{item.clientNif}</div>
                      </div>
                      <div className="w-1/12 flex justify-start items-center break-all px-2 border-l">
                        <div className="table">{item.value_received}</div>
                      </div>
                      <div className="w-1/12 flex justify-start items-center break-all px-2 border-l">
                        <div className="table">
                          {item.received_date
                            ? moment(item.received_date).format('YYYY-MM-DD')
                            : ''}
                        </div>
                      </div>
                      <div className="w-1/12 flex justify-start items-center break-all px-2 border-l">
                        <div className="table">
                          {item.service_year
                            ? moment(item.service_year).format('YYYY')
                            : ''}
                        </div>
                      </div>
                      <div className="w-2/12 flex justify-start items-center break-all whitespace-pre-line px-2 border-l">
                        <div className="table">
                          {!item.invoice_number
                            ? 'Sem Nº Fatura'
                            : item.isUpdate
                            ? 'Substituída'
                            : 'Novo'}
                        </div>
                      </div>
                      <div className="w-3/12 flex justify-start items-center break-all whitespace-pre-line px-2 border-l">
                        <div className="table">{item.observation}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </Modal>
        )}
      </div>
    );
  }
}

export default withRouter(TableHeader);
